<template>
  <div>
    <CampusHeader
        :can-create="canCreate"
        :activeTab="0"
        :show-campus="false"
        @start-creating="startCreatingObjective"
        :manage-campuses="false"
        :can-select="false"
        :header-text="headerText"
        :create-text="createText"
        :hide-tabs="true"
        :header_tabs="[{ title: `${tabEntity} List` }]"
    ></CampusHeader>
    <section class="section pt-4 box" >
      <div class="">
        <!--        <div class="columns">-->
        <!--          <div class="column">-->
        <!--            <b-field grouped>-->
        <!--              <b-field expanded>-->
        <!--                <b-select :disabled="$store.state.campus.all_campuses" expanded placeholder="Phase"-->
        <!--                          v-model="phase_filter">-->
        <!--                  <option-->
        <!--                      v-for="phase in phases"-->
        <!--                      :key="phase.id"-->
        <!--                      :value="phase.id"-->
        <!--                  >{{ phase.name }}-->
        <!--                  </option>-->
        <!--                </b-select>-->
        <!--              </b-field>-->
        <!--              <b-field expanded>-->
        <!--                <b-select :disabled="!phaseSearchId" expanded placeholder="Stage"-->
        <!--                          v-model="stageSearchId">-->
        <!--                  <option-->
        <!--                      v-for="stage in stages"-->
        <!--                      :key="stage.id"-->
        <!--                      :value="stage.id"-->
        <!--                  >{{ stage.name }}-->
        <!--                  </option>-->
        <!--                </b-select>-->
        <!--              </b-field>-->
        <!--              <b-field>-->
        <!--                <b-button @click="clear()">-->
        <!--                  Clear-->
        <!--                </b-button>-->
        <!--              </b-field>-->
        <!--            </b-field>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <hr>-->
        <b-field grouped>
          <b-field expanded label="Search">
            <!--suppress HtmlUnknownAttribute -->

            <b-input
                v-debounce:300ms="getFilteredObjectives"
                placeholder="Search"
                :loading="loadingFilter"
                v-model="searchHold"
            ></b-input>
          </b-field>
          <b-field label="Search Type">
            <b-switch v-model="searchType"
                      true-value="Reference"
                      false-value="Content">
              {{ searchType }}
            </b-switch>
          </b-field>
        </b-field>
        <b-table
            class="margin-top is-clickable"
            :data="objectives"
            :loading="loadingData"
            :striped="true"
            :hoverable="true"
            :bordered="false"
            :backend-sorting="true"
            :default-sort="['reference', 'asc']"
            @sort="onSort"
            @click="editObjective"
        >
          <template #empty>
            <div class="has-text-centered">No {{headerText}}</div>
          </template>
          <b-table-column
              v-slot="props"
              label="ID"
              field="id"
              sortable
              width="40"
              numeric
          >{{ props.row.id }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              sortable
              field="reference"
              label="Objective Reference"
          >{{ props.row.reference }}
          </b-table-column>
          <b-table-column
              v-slot="props"

              field="name"
              label="Content"
          >
            <div v-if="loaded" class="inline-objective-content">
              <ckeditor disabled :editor="editor" :value="clipDescription(props.row.name)"
                        :config="editorConfig"></ckeditor>
            </div>
          </b-table-column>
          <b-table-column
              v-slot="props"
              label=""
              custom-key="actions"
              centered

          >
            <div v-on:click.stop>
              <b-dropdown position="is-bottom-left" append-to-body aria-role="list" >
                <template #trigger>
                  <b-icon
                      :icon="$tc('icons.more')"/>
                </template>
                <b-dropdown-item @click="startDelete(props.row)"
                                v-if="canDelete"
                                aria-role="listitem">Delete
                </b-dropdown-item>
              </b-dropdown>
            </div>
          <!-- <b-button type="is-primary" v-on:click.stop @click="startDelete(props.row)">Delete</b-button> -->
          </b-table-column>
        </b-table>
        <b-pagination
            class="mt-4"
            :total="meta.total"
            :current="page"
            :range-before="2"
            :range-after="2"
            :per-page="limit"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            v-on:change="setPage"
        ></b-pagination>
      </div>
    </section>
  </div>
</template>

<script>
import CampusHeader from "@/components/panelled-dash/CampusHeader";
import Campus from "@/models/Campus";
import {mapState} from "vuex";
import Objective from "@/models/Objective";
import ObjectiveForm from "@/components/objectives/ObjectiveForm";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {mathEditorConfig} from "@/mathEditorConfig";
import clip from "text-clipper";

export default {
  name: "ObjectivesIndex",
  components: {CampusHeader},
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        ...{plugins: mathEditorConfig.plugins, toolbar: []},
        ...{placeholder: "Objective body"},

      },
      searchType: 'Reference',
      search: '',
      loadingFilter: false,
      objectiveDataHold: null,
      meta: Object,
      page: 1,
      order_by: 'reference',
      order_direction: 'asc',
      use_modal: false,
      limit: 20,
      loadingData: false,
      searchHold: null,
      phaseSearchId: null,
      stageSearchId: null,
      loaded:false
    };
  },
  props: {

    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default() {
        return "Objectives";
      },
    },
    createText: {
      type: String,
      default() {
        return "Objective";
      },
    },
    tabEntity: {
      type: String,
      default() {
        return "Objectives";
      },
    },

  },
  methods: {
    getFilteredObjectives(text) {
      this.search = text;
    },
    clipDescription(text) {
      return clip(text, 50, {
        html: true,
      });
    },
    clear() {
      this.phaseSearchId = null
      this.stageSearchId = null
    },
    onSort(field, order) {

      this.order_by = field;
      this.order_direction = order;
      this.setPage(this.page);

    },

    startDelete(objective) {
      this.$buefy.dialog.confirm({
        title: `Deleting objective`,
        confirmText: `Delete objective`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this objective?`,
        onConfirm: () =>
            Objective.Delete(objective.id)
                .then(() => {
                  this.$buefy.snackbar.open(`objective deleted!`);
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    startCreatingObjective() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
        },
        component: ObjectiveForm,
        hasModalCard: true,
        trapFocus: true,

      });
    },

    editObjective(objective) {
      this.setQuery({id: objective.id})
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          objective: objective
        },
        component: ObjectiveForm,
        fullScreen: false,
        hasModalCard: true,
        trapFocus: true,
        onCancel: () => {
          this.removeQuery(['id'])
        },
        events: {
          'close': () => {
            this.removeQuery(['id'])
          }
        }
      });
    }
    ,
    getFilteredCourses(text) {
      this.search = text;
    },
    setPage(pageNumber, initial = false) {
      this.loaded=false
      this.loadingData = true;
      this.objectiveDataHold = this.objectives;
      Objective.deleteAll();
      this.page = pageNumber;
      Objective.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          {
            ...this.filters, ...(this.order_by
                ? {
                  order_by: this.order_by,
                }
                : {order_by: "created_at"}),
            ...(this.order_direction
                ? {
                  order_direction: this.order_direction,
                }
                : {order_direction: "desc"}),
          }
          // , ['learning_stream']
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            this.loadingData = false;
            this.objectiveDataHold = null;
            this.loaded=true
            if (!initial) {
              // this.$cookies.set(
              //     `objective_filters`,
              //     JSON.stringify({
              //       page: this.page,
              //       phaseSearchId: this.phaseSearchId,
              //       stageSearchId: this.stageSearchId
              //     }),
              //     "1d"
              // );
            }
          }
      );
    }
  }
  ,
  watch: {
    //
    //   all_campuses(value) {
    //     if (value === true) {
    //       this.phaseSearchId = null
    //       this.stageSearchId = null
    //     }
    //   },
    //   selected_campus_id() {
    //     this.phaseSearchId = null
    //     this.stageSearchId = null
    //   },
    filters() {
      this.setPage(this.page);
    },
  },
  computed: {
    phase_filter: {
      set(val) {
        this.phaseSearchId = val
        this.stageSearchId = null
      }, get() {
        return this.phaseSearchId
      }
    },
    ...mapState('campus', ["selected_campus_id", "all_campuses"]),
    campuses() {
      return Campus.query().get();
    },
    filters() {
      return {
        // ...(this.order_direction
        //     ? {
        //       order_direction: this.order_direction,
        //     }
        //     : {}), ...(this.order_by
        //     ? {
        //       order_by: this.order_by,
        //     }
        //     : {}),

        ...(this.search.length > 0 && this.searchType === 'Reference'
            ? {reference: this.search}
            : {}), ...(this.search.length > 0 && this.searchType === 'Content'
            ? {name: this.search}
            : {}),
      };
    },
    objectives() {
      if (this.objectiveDataHold === null) {
        return Objective.query().orderBy(
            this.order_by,
            this.order_direction
        ).get();
      } else {
        return this.objectiveDataHold;
      }
    },

  },
  mounted() {
    // this.$store.dispatch("loader/show");
    // if (this.$cookies.get(`objective_filters`)) {
    //   let filters = JSON.parse(this.$cookies.get(`objective_filters`));
    //   this.page = filters.page;
    //   this.phaseSearchId = filters.phaseSearchId;
    //   this.stageSearchId = filters.stageSearchId
    //   this.order_by = filters.order_by
    //   this.order_direction = filters.order_direction
    //   this.$cookies.remove(`objective_filters`);
    // }

    this.setPage(this.page, true)
    if (this.$route.query.id) {
      Objective.FetchById(parseInt(this.$route.query.id)).then(() => {
        this.editObjective(Objective.find(parseInt(this.$route.query.id)))
      }).catch((err) => {
        this.handleError(err)
        this.removeQuery(['id'])
      })
    }
    // Promise.all([Phase.FetchAll({
    //   page: 1,
    //   limit: 999
    // }, {
    //   ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
    //       ? {campus_id: this.$store.state.campus.selected_campus_id}
    //       : {}),
    // }, ['stages'])]).then(() => {
    //   this.$store.dispatch("loader/hide");
    //   this.setPage(this.page, true);
    // }).catch(
    //     err => {
    //       this.$store.dispatch("loader/hide");
    //
    //       if (err.response.status === 422) {
    //         this.handleError(err)
    //       } else {
    //         this.handleError(err)
    //       }
    //     }
    // );
  },
};
</script>
