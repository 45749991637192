<template>
  <ObjectiveIndex
      :can-create="
      !!$store.getters['entities/user-permissions/find']('create objectives')
    "
      :can-delete="
      !!$store.getters['entities/user-permissions/find']('delete objectives')
    "
  ></ObjectiveIndex>
</template>
<script>


import ObjectiveIndex from "@/components/objectives/ObjectiveIndex";

export default {
  name: "Objective.index",
  components: {ObjectiveIndex},
};
</script>